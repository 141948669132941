import React from 'react'

import { GlobalStyles } from 'twin.macro'
import Seo from './Seo'
import Navigation from './Navigation'
import Footer from './Footer'

import '../styles/main.css'

const Layout = (props) => {
  const { children } = props

  return (
    <>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-MVMFRZS"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      <GlobalStyles />
      <Navigation />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
